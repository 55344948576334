<template>
  <div class="wrapper-content">
    <div class="detail">
      <div class="detail-image">
        <el-image :src="loadUrl(info.image)" fit="cover">
          <div slot="placeholder" class="image-slot">
            加载中<span class="dot">...</span>
          </div>
        </el-image>
      </div>
      <div class="detail-right">
        <h2>
          {{ info.name }}
          <el-tag v-if="info.status == 5" type="success" effect="dark" size="mini">筹备中</el-tag>
          <el-tag v-if="info.status == 7" type="danger" effect="dark" size="mini">报名中</el-tag>
          <el-tag v-if="info.status == 8" type="info" effect="dark" size="mini">报名已结束</el-tag>
          <el-tag v-if="info.status == 9" effect="dark" size="mini">比赛中</el-tag>
          <el-tag v-if="info.status == 10" type="info" effect="dark" size="mini">比赛已结束</el-tag>
        </h2>
        <div class="detail-right-textbg">
          <p>
            <span class="name">比赛时间：</span>
            <span class="value">{{ info.gameStartTime }} - {{ info.gameEndTime }}</span>
          </p>
          <p>
            <span class="name">比赛地点：</span>
            <span class="value">{{ showAddress(info) }}</span>
          </p>
          <p>
            <span class="name">报名费用：</span>
            <span class="value red fz22" v-if="info.collectFee == 1">
              <i class="f12">¥</i>{{ info.signFee }}
            </span>
            <span class="value red fz22" v-else> 免费</span>
          </p>
        </div>
        <div class="detail-right-text">
          <p>
            <span class="name">报名时间：</span>
            <span class="value">{{ info.signStartTime }} - {{ info.signEndTime }}</span>
          </p>
          <p v-for="(item, index) in competitionCompanyVos" :key="index">
            <span class="name">{{ item.title }}：</span>
            <span class="value">
              <span v-for="(items, indexs) in item.content" :key="indexs">{{ (indexs == 0 ? "" : "、") + items }}</span>
            </span>
          </p>
        </div>
        <template v-if="info.toMember==1">
          <el-button type="primary" :disabled="info.status != 7 || !isMember" v-if="isshowApple" @click="signup">参赛报名</el-button>
          <span class="vipIcon" v-if="info.toMember==1">(注：<img src="../../assets/image/vip-crownl.png" height="200" width="200"/>仅限会员报名)</span>
        </template>
        <template v-else>
          <el-button type="primary" :disabled="info.status != 7" v-if="isshowApple" @click="signup">参赛报名</el-button>
        </template>
      </div>
    </div>

    <el-tabs v-model="activeName" @tab-click="handleClick" class="detail-tab">
      <el-tab-pane v-for="(item, index) in competitionExplainVos" :key="index" :label="item.title" :name="index.toString()">
        <div class="ql-editor" v-html="text"></div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {
  getCompetitionItemDetails,
  myJoinCompetitionTow,
  myJoinTrue,
  getTextarea,
} from "@/api/events";
import {memberIsMember} from "../../api/partners";
export default {
  name: "events-detail",
  components: {},
  data() {
    return {
      activeName: "0",
      groupList: [],
      itemEntity: [],
      minList: [],
      curGItem: {},
      info: {},

      bigDialogVisible: false,
      eventsInfo: {},
      preid: "",
      // 按钮状态
      toApplyBtn: 1,
      applyConfirmBtn: 2,
      myJoinCompetitionBtn: 2,
      lookOrderDetail: 2,
      applyData: {},
      isshowApple: true,
      id: "",
      competitionCompanyVos: [],
      competitionExplainVos: [],
      competitionItemFeeVos: [],
      text:'',
      isMember:false
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.$nextTick(() => {
      this.init();
    });
    this.$store.dispatch("saveHeadActive", "/events");
  },
  methods: {
    init() {
      const self = this;
      self.$store.commit("showLoading");
      getCompetitionItemDetails(self.id).then((res) => {
        if (res.code == 200) {
          // self.groupList = res.data.groupList;
          // self.itemEntity = res.data.itemEntity;
          // self.minList = res.data.minList;
          self.competitionCompanyVos = res.data.competitionCompanyVos;
          self.competitionExplainVos = res.data.competitionExplainVos;
          self.competitionItemFeeVos = res.data.competitionItemFeeVos;
          self.info = res.data;
          this.getMemberIsMember(res.data.tenantId)
          // self.myJoinCompetitionTows();
          self.$nextTick(()=>{
            this.getTextareaFun(this.competitionExplainVos[0].content)
          })
        } else {
          self.$message(res.msg);
        }
        self.$store.commit("hideLoading");
      }).catch(()=>{
        self.$message('请求出错，请重新刷新页面！');
        self.$store.commit("hideLoading");
      });
    },
    //查询当前用户是否为会员
    getMemberIsMember(tenantId){
      let userId = window.localStorage.getItem("user")&&JSON.parse(window.localStorage.getItem("user")).userInfo.userId || "";
      memberIsMember(tenantId,userId).then(res=>{
        if(res.code == 200){
          this.isMember = res.data
        }
      })
    },
    // 状态，按钮控制
    myJoinCompetitionTows() {
      const self = this;
      self.$store.commit("showLoading");
      myJoinCompetitionTow({ itemId: this.itemEntity.id }).then((res) => {
        if (res.code == 200) {
          this.toApplyBtn = res.data.toApplyBtn;
          this.applyData = res.data.applyConfirmDetail;
          this.applyConfirmBtn = res.data.applyConfirmBtn;
          this.lookOrderDetail = res.data.lookOrderDetail;
          this.myJoinCompetitionBtn = res.data.myJoinCompetitionBtn;
          self.isshowApple = true;
        } else if (res.code == 600) {
          self.applyConfirmBtn = -1;
          self.isshowApple = false;
        } else {
          self.$message(res.msg);
        }
        self.$store.commit("hideLoading");
      });
    },
    // myJoinTrue
    myJoinTrues() {
      return;
    },
    // 回显地址
    showAddress(item) {
      return `${item.province}${item.city}${item.area}${item.address}`;
    },
    // 选中组别
    chooseGroup(gItem) {
      this.curGItem = gItem;
    },
    // 去报名：参赛报名
    signup() {
      // let competitionCompanyVos = JSON.stringify(this.info.competitionCompanyVos)
      // let competitionExplainVos = JSON.stringify(this.info.competitionExplainVos)
      // let competitionItemFeeVos = JSON.stringify(this.info.competitionItemFeeVos)
      // let competitionItemVos = JSON.stringify(this.info.competitionItemVos)
      // let dataInfo = JSON.stringify(this.info);
      // if (this.toApplyBtn == 1) {
      //   this.$router.push({
      //     path: "/events-signup",
      //     query: {dataInfo,competitionCompanyVos,competitionExplainVos,competitionItemFeeVos,competitionItemVos},
      //     // params:this.info
      //   });
      // } else {
      //   this.$message("请您先完成上一订单！");
      // }
      this.$router.push({path:"/events-signup",query:{id:this.id}})
    },
    cancel() {
      this.bigDialogVisible = false;
    },
    // 我的报名
    mygolink(num) {
      let gItem = JSON.stringify(this.curGItem);
      let eInfo = JSON.stringify(this.itemEntity);
      let active = num;
      let applytime = num;
      this.$router.push({
        path: "/events-signup",
        query: { gItem, active, applytime, eInfo },
      });
    },
    // 查看订单信息
    gorderlink() {
      this.$router.push({
        path: "/events-orderinfo",
        query: { itemId: this.itemEntity.id },
      });
    },

    // 确认报名
    confirmationsignup(status) {
      const self = this;
      // itemId ， status：2 同意，3 拒绝
      let params = {
        itemId: self.itemEntity.id,
        status,
      };
      myJoinTrue(params).then((res) => {
        if (res.code == 200) {
          window.location.reload();
        } else {
          this.$message(res.msg);
        }
        this.cancel();
      });
    },
    handleClick(tab) {
      this.getTextareaFun(this.competitionExplainVos[tab.index].content)
    },
    //获取详情
    getTextareaFun(journalisContent){
      let params = { "journalisContent": journalisContent }
      getTextarea(params).then(res =>{
        if(res.code == 200){
          this.text = res.data.dataHtml
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.detail {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 44px;
  .detail-image {
    flex-shrink: 0;
    width: 460px;
    height: 338px;
    margin-right: 20px;
    .el-image {
      width: 100%;
      height: 100%;
    }
  }
  .detail-right {
    flex: 1;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #555555;
    h2 {
      height: 22px;
      line-height: 22px;
      font-size: 22px;
      font-weight: bold;
      color: #333333;
    }
  }

  .detail-right-text,
  .detail-right-textbg {
    padding: 4px 8px;
    line-height: 26px;
    margin-bottom: 10px;
    p {
      display: flex;
      align-items: start;
    }
    .name {
      flex-shrink: 0;
      //width: 70px;
      text-align: right;
      color: #999;
    }
    .value {
      flex: 1;
    }
    .red {
      color: #ff1d00;
    }
    .fz22 {
      font-size: 22px;
    }
    i.f12 {
      font-size: 12px;
    }
  }
  .detail-right-textbg {
    margin-top: 20px;
    margin-bottom: 0;
    line-height: 31px;
    background: url("../../assets/image/detail-textbg.png") center no-repeat;
  }
}
.detail-tab {
  width: 100%;
  margin: 40px 0;
  min-height: 300px;
  color: #555;
  /deep/ .el-tabs__nav-wrap::after {
    height: 1px;
    background-color: #e8e8e8;
  }
}
.detail-item-list {
  padding-bottom: 22px;
  display: flex;
  flex-wrap: wrap;
  li {
    width: 132px;
    height: 34px;
    line-height: 34px;
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 4px;
    margin-left: 9px;
    font-size: 14px;
    .el-button--text {
      color: #333333;
      padding: 0 12px;
      white-space: nowrap;
      overflow: hidden;
      height: 100%;
      text-overflow: ellipsis;
      width: 141px;
    }
    &:first-child {
      flex-shrink: 0;
      width: 70px;
      height: 15px;
      line-height: 15px;
      color: #999999;
      border: 0;
    }
    &:hover,
    &.onSel {
      border-color: #1e50ae;
      color: #1e50ae;
      cursor: pointer;
    }
    &.onSel {
      position: relative;
      background-color: #fff;
      &:before {
        position: absolute;
        font-family: iconfont;
        content: "\ec9e";
        bottom: 0;
        right: 0;
        font-size: 10px;
        line-height: 10px;
        color: #fff;
        z-index: 4;
      }
      &:after {
        position: absolute;
        content: "";
        bottom: 0;
        right: -0.5px;
        width: 0;
        height: 0;
        border-bottom: 18px solid #1e50ae;
        border-left: 20px solid transparent;
      }
    }
  }
}

// 弹出层
.certificationDialog {
  .bold {
    font-weight: bold;
  }
  /deep/ .el-form-item__label {
    text-align: left;
  }
  /deep/ .el-dialog {
    width: 586px;
    box-shadow: 0px 9px 40px 0px rgba(75, 75, 75, 0.12);
    border-radius: 10px;
  }
  /deep/ .el-dialog__header {
    text-align: left;
    font-size: 18px;
    color: #333333;
    padding-bottom: 18px;
    border-bottom: solid 1px #e5e5e5;
  }
}

.confirmation-info {
  line-height: 42px;
  font-size: 14px;
  font-weight: 400;
  color: #555555;
}
.vipIcon{
  vertical-align: bottom;
  margin-left: 10px;
  color: #00A384;
  font-weight: bold;
  img{
    width: 20px;
    height: 20px;
  }
}
</style>
